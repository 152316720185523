.top_section {
  // background-image: url(../img/image1.png);
  // background-repeat: no-repeat;
  // background-size:cover;
}

.chartjs-tooltip {
  position: absolute;
  z-index: 1021;
  padding: 0.25rem 0.5rem;
  color: #fff;
  font-size: 14px;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: all 0.15s ease;
  transform: translate(-50%);
  border-radius: 0.25rem;
}

#drawer_drawer .MuiPaper-root{
  background: radial-gradient(235.24% 218.89% at 106.31% -18.52%, #CF214E 0%, #1B1B1B 24.48%, #1B1B1B 62.3%, #FEB931 94.98%);
  #mobile_drawer{
  
    .MuiList-root, .MuiListItemIcon-root svg{
      color: white;
    }
    
    .MuiTypography-root{
      font-family: Inter, sans-serif !important;
    
    }
    
    .MuiDivider-root{
      border-color: white;
      color: white;
      background-color: white;
    }
  }
}